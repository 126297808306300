iframe {
    width: 0 !important;
    height: 0 !important;
}

.validation-error {
    margin-top: 3px;
    font-size: 13px;
}

.text-danger {
    font-size: 12px !important;
}